import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "新体制になったメドレー開発組織について CTO 2 人に語ってもらいました",
  "date": "2023-04-27T17:22:45.000Z",
  "slug": "entry/2023/04/28/022245",
  "tags": [],
  "hero": "./2023_04_28.png",
  "heroAlt": "新体制になったメドレー開発組織について CTO 2 人に語ってもらいました"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`みなさん、こんにちは。技術広報・エンジニアの平木です。`}</p>
    <p>{`既に 2 月に`}<a parentName="p" {...{
        "href": "https://ssl4.eir-parts.net/doc/4480/tdnet/2238569/00.pdf"
      }}>{`発表`}</a>{`されていますが、4 月より弊社の経営体制を大幅に変更しました。開発組織について大きく変わった部分として CTO 2 名体制になった点があります。そこで、なぜ CTO を 2 名にしたのかや、これからのメドレーの開発組織についてを CTO になった 2 人にインタビューしました。`}</p>
    <h1>{`インタビュイー紹介`}</h1>
    <h2>{`稲本さん`}</h2>
    <p>{`執行役員。人材プラットフォーム(以下、人材 PF)CTO。独立系 SIer でのインフラエンジニアに始まり、インターネット企業での様々なサービスのインフラ構築を経て、音楽配信サービスやインターネットラジオサービスのサーバサイドエンジニアとして従事。2014 年メドレー入社後は創業時から運営しているジョブメドレーのプロダクト開発に従事。その後、同サービスのリードエンジニア、開発責任者を経て、2023 年 4 月より現職。`}</p>
    <h2>{`田中さん`}</h2>
    <p>{`執行役員。医療プラットフォーム(以下、医療 PF)CTO。独立系 SIer でのアプリケーションエンジニアや IT コンサルタントを経て、株式会社サイバーエージェントでサーバサイドエンジニアとしてソーシャルゲームや動画サービスなどの開発立ち上げに従事。2016 年メドレー入社後は CLINICS カルテの立ち上げを経験。その後 CLINICS 開発責任者を経て医療 PF プロダクト横断基盤の立ち上げ・開発責任者を経て 2023 年 4 月より現職。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f2b211f595a8845aec40828c163cc3cf/eea4a/note04_003.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAE0ootciCf/xAAcEAABBAMBAAAAAAAAAAAAAAADAAECEQQUITH/2gAIAQEAAQUCgZolyDLc5VFl7br/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAABBAMAAAAAAAAAAAAAAAAAARExQRAhIv/aAAgBAQAGPwK2gZLOk3mT/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIUFRcf/aAAgBAQABPyG0aPDn2U4nq4YCBNyUGgN5GoVh6J//2gAMAwEAAgADAAAAEODf/8QAFhEAAwAAAAAAAAAAAAAAAAAAEBFB/9oACAEDAQE/EIx//8QAFhEBAQEAAAAAAAAAAAAAAAAAABFB/9oACAECAQE/ENiP/8QAHBABAQABBQEAAAAAAAAAAAAAAREAITFRYXGR/9oACAEBAAE/EEJFOgeHsyV1jJ2I0PcAiQE037gIMZgmMAWbr3hjt5c//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note04 003",
            "title": "集合写真",
            "src": "/static/f2b211f595a8845aec40828c163cc3cf/e5166/note04_003.jpg",
            "srcSet": ["/static/f2b211f595a8845aec40828c163cc3cf/f93b5/note04_003.jpg 300w", "/static/f2b211f595a8845aec40828c163cc3cf/b4294/note04_003.jpg 600w", "/static/f2b211f595a8845aec40828c163cc3cf/e5166/note04_003.jpg 1200w", "/static/f2b211f595a8845aec40828c163cc3cf/eea4a/note04_003.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`左から田中さん、稲本さん`}</em></p>
    <h1>{`CTO を 2 名体制にして開発組織の体制をアップデートした理由`}</h1>
    <p><strong parentName="p">{`平木`}</strong>{`: さっそくですが、2023 年 4 月よりお二人がそれぞれ人材 PF と医療 PF の CTO に就任されて、改めて開発組織のアップデートがされましたが、どういった背景でこの体制になったんでしょうか。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{`: 会社全体の組織設計として医療 PF と人材 PF に分かれたというのがまず最初にあったんですが、当初、開発組織は両 PF を横断する形で 1 人の CTO が見ていました。各 PF 内にそれぞれプロダクトがあり開発もプロダクトごとにチームを分けていましたが、それぞれの開発チームが有機的に動きつつも組織全体の小回りの効きやすさなども考えて、こうした体制になっていました。`}</p>
    <p>{`ただ、時間が経つに連れ段々と開発チームも各 PF 自体 の規模も大きくなってきたので、 1 人の CTO ではマネジメントが難しくなってきました。また医療 PF と人材 PF で共通する開発組織の文化などはありますが、扱っている事業内容の差異から選定技術やプロジェクトの進め方などの違う部分も出てきたので、それぞれの PF で CTO を置いて組織面・技術面の課題に対応しようということになったためです。`}</p>
    <p><strong parentName="p">{`稲本`}</strong>{`: 開発組織の人数としては 100 名を越えているのですが、それだけではなく就業場所の違いやサービスの多様化など、それぞれの PF で特性が違う課題がこれから先も出てくるだろうということで、先を見据えての開発組織のアップデートの一環として CTO 2 名体制にしたというところですね。`}</p>
    <p><strong parentName="p">{`平木`}</strong>{`: これからの組織の変化を見据えた動きだということですね。`}</p>
    <h1>{`新しい開発体制になって変わっていく部分・変わらない部分`}</h1>
    <p><strong parentName="p">{`平木`}</strong>{`: 新体制になってまだ日が浅いですが、これから変えていく部分というのはどういったところになっていくんでしょうか。`}</p>
    <p><strong parentName="p">{`稲本`}</strong>{`: 自分達 2 人が持っている色々な役割を分解していって他のメンバーへ役割を委譲していくことですね。CTO が色々持ちすぎると組織のスケールに対しボトルネックになることは目に見えているので。`}</p>
    <p>{`それ以外には、役割を担ったチームやメンバーが自身で思考し行動しやすい組織にしていくことで、更なるチャレンジがしやすかったりパフォーマンスを発揮しやすい状態にしていけたらと考えています。`}</p>
    <p><strong parentName="p">{`平木`}</strong>{`: なるほど。田中さんは何かありますか。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{`: 「役割の明確化」もその 1 つですが、将来取り組むべき課題に向けて中長期を見据えた開発組織の設計や運営に向き合うフェイズになってきたと感じており、強化していきたいと考えています。`}</p>
    <p><strong parentName="p">{`平木`}</strong>{`: 将来の課題に対して技術・組織なども先手を打っていくイメージですね。`}</p>
    <h1>{`新体制での開発組織のミッション`}</h1>
    <p><strong parentName="p">{`平木`}</strong>{`: それでは新体制になって改めて開発組織のミッションとしてはどういったものがありますか。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{`: 会社全体のミッションと基本は一緒ではあります。「医療」という大きい領域の課題に対して、何のために開発をするのかや、その開発をすることによってどういった顧客価値を提供できるのかというところは、ちゃんとミッションに紐付いている部分なので変えずにいきます。`}</p>
    <p>{`その上で、有効な施策をどれだけ素早くデリバリーできるかというところには今以上に注力していきたいところです。そのためには無駄を省き、やるべき事に集中してより生産性の高い開発を目指します。`}</p>
    <p>{`プロダクト開発において無駄な機能開発を行なうと、後からそこを削ろうと思っても難しいですし、作った結果誰も幸せにならないということになってしまいます。こうならないためにもスピード感は落とさないで、事業側メンバーと協力しながら適切な要件整理をしたりなど、どうやって顧客へ適切なプロダクトを届けるか?という部分により重きを置いていきたいです。`}</p>
    <h1>{`事業とプロダクト開発の関係性`}</h1>
    <p><strong parentName="p">{`平木`}</strong>{`: さて、次に先程のお話にも出てきましたが、事業とプロダクト開発の関係性についてお聞きします。お二人が考えるこの関係性はどのようなものでしょうか。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{`: ここも以前から変わらないスタンスですが、改めて定義するなら「テクノロジーを最大限に使って医療領域の課題を解決する」という関係になります。テクノロジーとメドレーの事業である医療領域の課題解決という 2 つの側面のどれが欠けても、プロダクトとして良いものは生まれないと考えています。`}</p>
    <p><strong parentName="p">{`平木`}</strong>{`: 主に Web のテクノロジーを適材適所で課題解決のために使っていくというスタンスですね。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{`: そうですね。先に来るのはあくまでも、「医療領域の課題解決をする」という部分になるのですが、その手段として必要な技術は全部使っていこうという姿勢です。なので、まずは開発組織のメンバー一人ひとりが、ドメインの理解をするということが始めの一歩です。そうして課題の本質を見極めて解決に必要な手段は何があるのかを考えていく必要が出てきます。`}</p>
    <p>{`解決のために最適であれば、その技術の新旧や使用実績などを問わず取り入れて開発していくという心構えですね。そのためにはきちんと技術動向を追っていき日頃からユースケースなどを想像しながら、その技術を触ったりする必要も出てきます。`}</p>
    <p><strong parentName="p">{`稲本`}</strong>{`: ドメイン知識とテクノロジーを最大限に駆使し、プロダクト開発を通して価値を届けるというのが我々のミッションなのかなと思います。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7cfeaa59460c981bd77861b5f5260f11/eea4a/note04_002.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAaUnaY8dTK//xAAcEAABAwUAAAAAAAAAAAAAAAABAgMRAAQhMTP/2gAIAQEAAQUCdIhiErJzcdDs1//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AYf/xAAbEAAABwEAAAAAAAAAAAAAAAAAAQIQITFBgf/aAAgBAQAGPwJRHoVN4Kbrf//EABsQAAICAwEAAAAAAAAAAAAAAAABESExUWHh/9oACAEBAAE/IV7jrA2vKEwM4KXC8KI1qZH/2gAMAwEAAgADAAAAEO8v/8QAFhEBAQEAAAAAAAAAAAAAAAAAARBB/9oACAEDAQE/EByf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxDatGf/xAAaEAEAAwEBAQAAAAAAAAAAAAABABExIWGB/9oACAEBAAE/ECkONAFIZ7krqzRjfdly3ItiI0Xw5AwDofIS/Cf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note04 002",
            "title": "稲本さん",
            "src": "/static/7cfeaa59460c981bd77861b5f5260f11/e5166/note04_002.jpg",
            "srcSet": ["/static/7cfeaa59460c981bd77861b5f5260f11/f93b5/note04_002.jpg 300w", "/static/7cfeaa59460c981bd77861b5f5260f11/b4294/note04_002.jpg 600w", "/static/7cfeaa59460c981bd77861b5f5260f11/e5166/note04_002.jpg 1200w", "/static/7cfeaa59460c981bd77861b5f5260f11/eea4a/note04_002.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`稲本さん`}</em></p>
    <p><strong parentName="p">{`平木`}</strong>{`: 医療領域の未来を変えていくために、技術を最大限に用いたプロダクトドリブンであることが大切ということですね。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{`: メドレーではそれだけじゃない部分も意識していますね。一言で「プロダクト」と言ってもサービスとそれを作る開発者というだけではなく、顧客接点を持つ事業部などのメンバー全ての動きも合わせて「プロダクト」という意識を持っています。`}</p>
    <p><strong parentName="p">{`平木`}</strong>{`: なるほど、プロダクトに関わっている人全員を合わせて「プロダクト」だよということですか。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{`: 例えば開発メンバーが良いプロダクトを提供したとしても、そのプロダクトを使ったユーザーが疑問に思った部分があったとします。そこで問い合わせをしたときに、その体験が良くない場合はやっぱり「プロダクトに関する体験が良くない」という印象になってしまいますよね。これはセールスなど他の領域でも起き得る話ですが、メドレーではそういった部分も含めて「プロダクト開発」という意識を持っているので、他部署だからということではなくトータルでユーザーに価値を感じてもらえるようにしていくということを大切にしています。`}</p>
    <p><strong parentName="p">{`平木`}</strong>{`: 確かにユーザーはそういった関わるもの全部一緒に「プロダクト」と思いますもんね。`}</p>
    <p><strong parentName="p">{`稲本`}</strong>{`: 日々の業務の中で直接ユーザーと対話してくれている方々がたくさんいます。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/team/culture.html"
      }}>{`Our Essentials`}</a>{`(以下、OE)で特に好きなものの一つで「信頼を獲得する」というものがあります。OE 自体は主に社内向けのメッセージにはなるんですが、日々の業務の中で他者からの信頼を得ることが出来るような振る舞いや成果を出すこと、その信頼の積み重ねがプロダクト全体にも反映され、結果としてユーザーからも信頼される/価値を感じてもらえるプロダクトの提供につながっているのだと感じています。`}</p>
    <h1>{`プロダクトと技術の関係について`}</h1>
    <p><strong parentName="p">{`平木`}</strong>{`: もう少し開発についての話を掘り下げていこうと思います。弊社では現在のところ Ruby や Ruby on Rails(以下 Rails)で作られているプロダクトが大半ではありますが、Go や Node.js などをメインに使っているプロダクトもありますよね。そうした技術選定のときの基本姿勢としてはどういったものがありますか。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{`: 基本として、「それぞれのプロダクトにとって現時点でこの技術で開発するのが、良いことだよね」というのを大切にしています。組織的に知見が多いということもあって、Rails を使っているプロダクトが多いのはそうなんですが、この技術じゃないとダメという縛りがあるわけではないです。Go や Node.js
にしても、そのプロダクトに現状最適だという視点で技術選定をしています。`}</p>
    <p><strong parentName="p">{`稲本`}</strong>{`: 今までできなかったことが、新しい技術を使ったら解決するのにという場面も結構あると思います。そういうときに「今使ってないから…」というのではなく、その技術を使ったほうがプロダクトにプラスになると判断したら使っていくようにしています。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{`: ですので、今プロダクトで使っている技術で守っていくというより、個々人でアンテナを張って新しい技術は積極的にキャッチアップしていきながら、チーム内で「これ使うと良さそうだからやっていこう」という感じですね。`}</p>
    <p><strong parentName="p">{`平木`}</strong>{`: 先程も出てきたユーザーに価値提供を素早くするという要素の一つということですね。そうすると、例えば開発者体験を良くするような技術やフローなんかを取り入れるというのも、そうした志向の一部ということになりますか?`}</p>
    <p><strong parentName="p">{`田中`}</strong>{`: そうですね。良いものを早くユーザーに届けるという一側面になります。開発者体験を良くするというのが最終的な目的ではなくて、その結果としてチームの生産性が上がるのなら体験を良くすると良いよねという。プロダクトコードの負債解消とかもそうですが、バランスが確かに難しいのですが、こういったところを疎かにすると結果として顧客への十分な価値提供ができなくなってしまうリスクもあるので、短期と中長期できちんとバランシングしつつ開発に取り組んでいきたいと思っています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1d6aff5695f597816027edea06055de1/eea4a/note04_001.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAe7EvM24Jh//xAAbEAABBAMAAAAAAAAAAAAAAAACAAEDNAQRE//aAAgBAQABBQLJbZQSCw9FJZCyv//EABYRAQEBAAAAAAAAAAAAAAAAAAARQf/aAAgBAwEBPwHEf//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAaEAAABwAAAAAAAAAAAAAAAAAAAQISICFB/9oACAEBAAY/Ak2G6Uf/xAAeEAACAgAHAAAAAAAAAAAAAAABEQAhEDFBYbHB4f/aAAgBAQABPyFXoxlLTejtCD9hkgzOLrD/2gAMAwEAAgADAAAAEMzP/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QIf/EABcRAQADAAAAAAAAAAAAAAAAAAABETH/2gAIAQIBAT8QyV3/xAAcEAEAAgIDAQAAAAAAAAAAAAABACERMUFhcfD/2gAIAQEAAT8QQiqbbrccHMNEMcNxqo++TOY4Qt6lQFXh3P/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note04 001",
            "title": "田中さん",
            "src": "/static/1d6aff5695f597816027edea06055de1/e5166/note04_001.jpg",
            "srcSet": ["/static/1d6aff5695f597816027edea06055de1/f93b5/note04_001.jpg 300w", "/static/1d6aff5695f597816027edea06055de1/b4294/note04_001.jpg 600w", "/static/1d6aff5695f597816027edea06055de1/e5166/note04_001.jpg 1200w", "/static/1d6aff5695f597816027edea06055de1/eea4a/note04_001.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`田中さん`}</em></p>
    <p><strong parentName="p">{`平木`}</strong>{`: 改めてここで現在の開発体制について伺っていければと思います。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{`: 人材 PF も医療 PF もプロダクトに紐付いて複数のチームに分かれていて、チームの人数規模としてみると多少の上下はあるんですが、プロダクトマネージャ(以下、PdM) やデザイナー、エンジニアを合わせて 10 人前後というチームが多いです。`}</p>
    <p><strong parentName="p">{`平木`}</strong>{`: そうしたチーム内で、特にエンジニアはどのような役割分担をしていることが多いですか?`}</p>
    <p><strong parentName="p">{`稲本`}</strong>{`: もちろんチームによっても違ってくるんですが、チームの中に PM と TL を置いて、そのチームの中にメンバーが数人いる形が多いですね。この単位をベースにして toC 向けのプロジェクト、toB 向けのプロジェクトのような感じでチームを分けて開発を行なっています。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{`: メンバー個々人はサーバサイドやフロントエンド、インフラのような得意領域がありそこを考慮した開発をしていっていますが、サーバサイドだけやる人という形ではなく、得意領域と隣り合う領域についても意識し、可能な限り理解してコラボレーションしていこうというやり方を取っています。これによりコミュニケーションコストが下がることで生産性高く、品質向上にも寄与するというのが理由になります。`}</p>
    <p>{`もちろんプロとして自分の得意分野で力を存分に発揮はしてもらうというのは大前提ですが、その強みを周囲のメンバーに還元しつつ、得意領域以外はそこが得意なメンバーから還元してもらいながら、良いプロダクトを作っていくというのがメドレーの開発組織のスタンスになります。`}</p>
    <h1>{`開発組織の雰囲気やメンバーの働き方について`}</h1>
    <p><strong parentName="p">{`平木`}</strong>{`: 今まで開発組織の制度的な側面を中心に聞いてきましたが、組織のソフト面についてお聞きします。メドレーの開発組織の雰囲気ですが、どんな雰囲気だったりしますか?`}</p>
    <p><strong parentName="p">{`稲本`}</strong>{`: 全体的にはわりと和やかな雰囲気は持ちつつも、各自のバリューに対してはストイックな感じかなと思います。`}</p>
    <p>{`自分たちで決めた期日など守るところはしっかり守ろうという意識を持ちつつ、無理な期日になりそうであればスケジュールやスコープを調整したり、プロジェクトの進め方で上手く行ったこと/行かなかったことを振り返りを通して改善を図ったりするなど、当たり前のことを高い水準でやり遂げていく習慣が根付いていると思います。`}</p>
    <p>{`コミュニケーションに関しては、非同期コミュニケーションがベースではあります。口頭での相談なんかももちろんしますが、認識がずれないように話したことを issue などに残したりしてます。 雑談みたいなものはミーティングの後半パートにあえて雑談パートを作るなどしていますが、業務中にずっとするようなことはないですね。開発中はそれぞれが集中していることが多いです。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{`: チームごとに違いますが、朝会や夕会などでちゃんとコミュニケーションが取れるようにしているということが多いですね。なので、あんまり他の時間に雑談みたいなものが必要ないという感じです。`}</p>
    <p><strong parentName="p">{`平木`}</strong>{`: 和やかながらも、締めるところは締めるという雰囲気ですね。開発チームの皆さんはどんな働き方をしている人が多いですか?`}</p>
    <p><strong parentName="p">{`稲本`}</strong>{`: これも個々人で違っていますが出社とリモートのハイブリッドな方が多いかと思います。`}</p>
    <p>{`やはり開発に集中したい場面ではリモートの方が割り込みも少なくなりやすく集中しやすいですし、物事の認識を揃えたり決めていく場合は、ミーティングなどで実際に顔を合わせた方が効率も良いことはあると思いますね。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{`: 自分のバリューを最大化できるように働いていこうというのが基本になっています。`}</p>
    <p><strong parentName="p">{`平木`}</strong>{`: 各自ちゃんと考えてプロダクトに貢献できるようにということですね。そうした働き方の先にキャリアパスがあると思いますが、その観点ではどのようなパスがありますか?`}</p>
    <p><strong parentName="p">{`田中`}</strong>{`: 大きく分けては、マネジメントをメインにするか、テクニカル部分を特化させたスペシャリストとなるかという 2 つになります。役割分担にもちょっと関わりますが、内容としては、例えば PdM を目指していくというのも道としてはありますし、自分の得意領域を最大限に伸ばしてテックリードとして技術をもってプロダクトを引っぱっていくという道なんかもあります。1on1 や評価などでそうした部分は本人と刷り合わせをしていきながら決めていく形になります。`}</p>
    <p><strong parentName="p">{`平木`}</strong>{`: 評価のお話が出てきましたが、どういった観点で評価されますか?`}</p>
    <p><strong parentName="p">{`稲本`}</strong>{`: ベースとしては会社として決めた OE に沿ったものになります。OE を踏まえつつ、業務目標や技術的な目標を立ててそれができたかどうかが基準になりますね。`}</p>
    <p>{`立てた目標が色々な理由で達成できなかったというケースももちろん出てくると思いますが、内容として自分はどういった部分は頑張ったとか、どういう理由で達成できなかったなどの自分なりの説明ができるかというのを重視しています。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{`: 目標はチームバリューにいかに寄与したかを重視しています。OE に基づきチームとして達成しないといけない目標があってそれをブレイクダウンした上で、自分が寄与できるのはどこかという感じで目標にしてもらいます。一見目に見えにくい動きだったとしても、ちゃんとチームに貢献しているねとなれば、もちろん評価の対象になりますし、逆に例えばいくら頑張って新技術を習得したとなってもチームに貢献してなければ評価はされにくいです。`}</p>
    <p><strong parentName="p">{`平木`}</strong>{`: ありがとうございます。最後にメドレーで開発することの良さを教えてもらえればと思います。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{`: メドレーは長期を見据えて課題に取り組んでいる会社であり、泥臭く地道に積み重ねなければいけないこともすごく多いです。そのため短期で結果が見えづらいこともあるかもしれません。ですが、医療領域でどっしりと腰を据えて長期的に本質を捉えた開発をしているため、技術面だけではなくプロダクトデザインとして何のために、どのようなアプローチが適切か、という思考力や設計能力も身に付きやすいのではないかと思います。`}</p>
    <p><strong parentName="p">{`稲本`}</strong>{`: 長期で課題に取り組むとなると「同じことの繰り返しではないか」と感じる方もいるかもしれませんが、プロダクトが成長すればフェーズも変わりますし、取り組むことにも変化が生じていくので成長や変化を楽しめる人には合っているのではないかと思います。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`新体制で CTO 2 人にメドレーの開発組織について色々と語ってもらいました。`}</p>
    <p>{`2 人も話をしていましたが、メドレーでは長期思考・未来志向の考え方をベースに、ユーザーの本質的な課題はなにか、どうすればそれらを解決できるか?という部分にフォーカスを当てて開発をするという志向がとても強いと思います。`}</p>
    <p>{`そうした環境でじっくりと確実にユーザーに価値を提供できるという仕事だと思いますので、ご興味を持たれた方はぜひカジュアルにお話をさせていただければと思います!`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      